<template>
  <div class="safety" v-loading="loading">
    <el-form
      :model="form"
      :rules="rules"
      ref="safetyForm"
      label-width="140px"
      label-position="right"
      size="medium"
      class="small-form"
    >
      <el-form-item label="SMTP服务器地址：" prop="host">
        <el-input
          v-model="form.host"
          :disabled="status"
          placeholder="请输入服务器地址"
        ></el-input>
      </el-form-item>
      <el-form-item label="服务器端口：" prop="port">
        <el-input
          v-model="form.port"
          :disabled="status"
          placeholder="请输入服务器端口"
        ></el-input>
      </el-form-item>
      <el-form-item label="账号：" prop="account">
        <el-input
          v-model="form.account"
          :disabled="status"
          placeholder="请输入账号"
        ></el-input>
      </el-form-item>
<!--      <el-form-item label="授权密码：" prop="password">-->
<!--        <el-input-->
<!--          v-model="form.password"-->
<!--          :disabled="status"-->
<!--          placeholder="请输入授权密码"-->
<!--        ></el-input>-->
<!--      </el-form-item>-->

      <el-form-item label="授权密码：" prop="isset_password">
        <template v-if="form.isset_password">
          <span style="margin-right: 10px;">已填写</span><el-button type="text" @click="showInputDialog('修改密码')">修改</el-button>
        </template>
        <el-button v-else type="text" @click="showInputDialog('填写密码')">填写</el-button>
<!--        <div class="info">-->
<!--          <i class="el-icon-info" />-->
<!--          <span style="margin-left: 6px">微信公众号后台 -> 设置与开发 -> 开发 -> 基本配置 -> 公众号开发信息</span>-->
<!--        </div>-->
      </el-form-item>

      <el-form-item label="发件人邮箱：" prop="sender_address">
        <el-input
          v-model="form.sender_address"
          :disabled="status"
          placeholder="请输入发件人邮箱"
        ></el-input>
      </el-form-item>
      <el-form-item label="发件人姓名：" prop="sender_name">
        <el-input
          v-model="form.sender_name"
          :disabled="status"
          placeholder="请输入发件人姓名"
        ></el-input>
      </el-form-item>
      <!-- <el-form-item label="是否设置密码：" prop="isset_password">
        <el-radio-group v-model="form.isset_password" :disabled="status">
          <el-radio :label="0">否</el-radio>
          <el-radio :label="1">是</el-radio>
        </el-radio-group>
      </el-form-item> -->
<!--      <el-form-item label="收件人邮箱：" prop="to_email">-->
<!--        <el-input-->
<!--          v-model="form.to_email"-->
<!--          :disabled="status"-->
<!--          placeholder="请输入发件人姓名"-->
<!--        ></el-input>-->
<!--      </el-form-item>-->
      <el-form-item label="邮箱标题：" prop="title">
        <el-input v-model="form.title" placeholder="请输入发件人姓名"></el-input>
      </el-form-item>
      <el-form-item label="邮箱内容：" prop="content">
        <tinymce
          id="tinymce-editor"
          ref="editor"
          @choose="handleToolBarClick"
          v-model="form.content"
          show-article-import
          style="max-width: 540px"
        />
      </el-form-item>
    </el-form>
    <fixed-action-bar :z-index="2">
      <el-button
        v-show="!loading"
        size="medium"
        type="primary"
        @click="save('safetyForm')"
        :loading="status"
      >
        <span v-if="!status">保 存</span>
        <span v-else>保存中</span>
      </el-button>
    </fixed-action-bar>
    <!-- 富文本插入图片 -->
    <media-selector v-model="showImageList" multiple @select="updateImages" />
    <media-selector
      v-model="showVideoList"
      type="video"
      multiple
      @select="updateVideos"
    />
    <media-selector
      v-model="showAudioList"
      type="audio"
      multiple
      @select="updateAudios"
    />
    <!-- 文章导入 -->
    <import-article v-model="isShowImportDialog" @confirm="getImportDetail" />
    <!-- 导入HTML -->
    <import-html-code v-model="isShowHtmlInput" />

    <el-dialog v-if="inputDialog.show"
               class="dialog-vertical" width="450px"
               :visible="inputDialog.show" :title="inputDialog.title"
               @close="close">
      <el-form ref="inputDialog" class="small-form" size="medium"
               :model="inputDialog.data" :rules="inputDialog.rule"
               :label-width="'105px'"
               @submit.native.prevent>
        <el-form-item :label="'密码：'" prop="app_secret">
          <el-input v-model="inputDialog.data.app_secret" v-focus placeholder="请输入" />
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button size="small" @click="close">取消</el-button>
        <el-button size="small" type="primary" @click="confirm">确定</el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import {
  getDeliverConfig,
  saveDeliverConfig,
} from '@/modules/employ/api/employ-config'
import FixedActionBar from '@/base/layout/FixedActionBar'
import tinymceMixin from '@/modules/employ/mixin/tinymce-mixin'
import ImportHtmlCode from '../../../common/components/ImportHtmlCode'
import ImportArticle from '../../../../base/components/Editor/ImportArticle'
import MediaSelector from '../../../common/components/MediaSelector'
export default {
  mixins: [tinymceMixin],
  data() {
    var checkIsSet = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入授权密码'))
      }
      callback()
    }

    return {
      loading: false, //加载
      status: false, //保存状态
      form: {
        content: '',
        title: '',
        isset_password: 0,
        host: '',
        port: '',
        account: '',
        password: '',
        sender_address: '',
        sender_name: '',
      },
      rules: {
        host: [
          { required: true, message: '请输入服务器地址', trigger: 'blur' },
        ],
        port: [
          { required: true, message: '请输入服务器端口', trigger: 'blur' },
        ],
        account: [{ required: true, message: '请输入账号', trigger: 'blur' }],
        password: [
          { required: true, message: '请输入授权密码', trigger: 'blur' },
        ],
        sender_address: [
          { required: true, message: '请输入发件人邮箱', trigger: 'blur' },
        ],
        sender_name: [
          { required: true, message: '请输入发件人姓名', trigger: 'blur' },
        ],
        title: [
          { required: true, message: '请输入邮箱标题', trigger: 'blur' },
        ],
        content: [
          { required: true, message: '请输入邮箱内容', trigger: 'blur' },
        ],
        isset_password: [
          { required: true, message: "请输入授权密码", trigger: "blur" },
          { validator: checkIsSet, trigger: "blur" },
        ],
        // to_email: [
        //   { required: true, message: '请输入收件人邮箱', trigger: 'blur' },
        // ],
      },

      inputDialog: {
        show: false,
        loading: false,
        title: '',
        data: {
          app_secret: ""
        },
      }
    }
  },
  methods: {
    confirm() {
      this.form.password = this.inputDialog.data.app_secret
      this.$refs.inputDialog.validate(valid => {
        if (valid) {
          this.form.isset_password = 1
          this.close()
        }
      })
    },
    close() {
      this.inputDialog.show = false
      this.inputDialog.data.app_secret = ''
    },
    showInputDialog(title, isV3) {
      this.inputDialog.title = title
      this.inputDialog.isV3 = isV3
      this.inputDialog.show = true
    },
    /**
     * 设置导入成功的文章内容
     * 设置封面图和标题
     * @param {Object} e
     */
    getImportDetail(e) {
      this.$set(this.form, 'email_content', e.content)
      this.$refs.editor.$emit('change', e.content)
    },
    //获取配置
    getSecureConfig() {
      this.loading = true
      getDeliverConfig()
        .then((res) => {
          const { data } = res
          for (let key in data) {
            this.form[key] = data[key]
          }
          this.loading = false
        })
        .catch((err) => {
          this.loading = false
        })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    //保存
    save(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let postData = {
            config: {
              ...this.form,
            },
          }

          this.status = true
          //请求
          saveDeliverConfig(postData)
            .then((res) => {
              this.$message.success(res.msg)
              this.status = false
            })
            .catch((err) => {
              this.status = false
            })
        }
      })
    },
  },
  created() {
    //获取配置
    this.getSecureConfig()
  },
  components: {
    MediaSelector,
    ImportArticle,
    ImportHtmlCode,
    FixedActionBar,
  },
}
</script>
<style lang="scss" scoped>
.safety {
  min-height: 400px;
}
</style>
