import { render, staticRenderFns } from "./ImportHtmlCode.vue?vue&type=template&id=dc979048&scoped=true&"
import script from "./ImportHtmlCode.vue?vue&type=script&lang=js&"
export * from "./ImportHtmlCode.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dc979048",
  null
  
)

export default component.exports